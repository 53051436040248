.text_card {
	background: white;
	width: 100%;
	max-width: 700px;
	min-height: 200px;
	display: grid;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding: 50px 50px 50px 50px;
	border-radius: 8px;
	position: relative;
	animation: fade ease-in-out 1000ms;
	place-items: center;
}

.text_card > p {
	font-size: 1.2rem;
	line-height: 1.6;
	text-align: center;
	letter-spacing: 0.5px;
	margin-bottom: 0;
	animation: fade-in 400ms forwards;
	font-weight: 500;
}

.fade-out {
	opacity: 0;
	transition: opacity 400ms ease-out;
}

.audio_card {
	background: white;
	width: 100%;
	margin: 0 auto;
	padding: 10px 10px;
	font-weight: 400;
	line-height: 1.7;
	border-radius: 0px;
	letter-spacing: 0.8px;
	place-items: center;
	border-bottom: 2px solid rgb(209, 209, 209) !important;
}

.latest-rec {
	animation: fade-in-top-lg 800ms forwards;
	border: 2px solid var(--mainColor) !important;
}

.rec_amount {
	animation: fade-in-top 400ms forwards;
	font-weight: 600;
	margin-left: 1px;
}

.button_progress {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.2);
	transition: width 0.5s;
}

.button_text {
	position: relative;
}

.audio_card_btn {
	background: rgb(240, 240, 240);
	border-radius: 50%;
	font-weight: 600;
	font-size: 0.8rem;
	height: 35px;
	width: 35px;
	display: grid;
	place-items: center;
}

small {
	font-weight: 500;
	color: rgb(87, 87, 87);
	font-size: 0.8rem !important;
}

.audio_player_wrapper {
	display: grid;
	place-items: center;
	grid-template-columns: 15% 15% 55% 15%;
}

.audio_player_wrapper > h1 {
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: 0px;
	margin-bottom: 40px;
}

.rec_btn_layout {
	border: none;
	border-radius: 50%;
	color: #333333;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	margin-top: 40px;
}

.record_btn {
	background: var(--mainColor);
	padding: 36px;
	position: relative;
	/* margin-left: 42% !important; */
	margin-bottom: 10px !important;
	color: white !important;
	border: none;
}

.audio_player_wrapper > p {
	font-size: 0.8rem;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: 0.3px;
	max-width: 15ch;
	margin-bottom: 0px;
}
.record_btn:hover {
	background: var(--mainColorHover);
	transition: 300ms ease-in-out;
}

.new_text_btn {
	background: white;
	border: 2px solid rgb(212, 212, 212);
	border-radius: 50%;
	height: 50px;
	width: 50px;
	color: #464646;
}

.new_text_btn:hover {
	color: var(--mainColor) !important;
}

.new_text_wrapper {
	position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
}

.rec_session_container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	place-items: center;
	margin-top: 30px;
}
.rec_session_container_latest {
	gap: 20px;
	display: flex;
	align-items: center;
	max-width: 300px;
}
.rec_session_container > h1 {
	font-size: 1.3rem;
	text-align: center;
}
.text_rec_wrapper {
	display: grid;
	place-items: center;
}

.recording_btn_active {
	background: #9d2929;
	padding: 36px;
	color: white !important;
	/* z-index: -1; */
	position: relative;
	/* margin-left: 42% !important; */
	margin-bottom: 10px !important;
	position: relative;
}

.recording_btn_active::before {
	content: '';
	width: 100px;
	height: 100px;
	background: #9d2929;
	opacity: 0.5;
	position: absolute;
	border-radius: 50%;
	animation-duration: 2s;
	animation-name: pulse;
	animation-iteration-count: infinite;
	/* z-index: -1; */
}

.del_btn_float {
}

.form-check-input {
	width: 25px !important;
	height: 25px !important;
}

.check-item {
	margin-left: 20px;
}

.check-item-2 {
	align-self: center;
}

.check-grid {
	display: grid;
	grid-template-columns: 7% 93%;
	margin-bottom: 20px;
	gap: 10px;
	margin-top: 30px;
}

[type='checkbox'] {
	border: 2px inset #266da7 !important;
	border-radius: 25px !important;
	cursor: pointer !important;
}

.form_wrapper {
	animation: fade-in-top 400ms forwards;
	display: grid;
	place-items: center;
	margin-top: 10px;
}

form {
	display: grid;
	place-items: center;
}
.checkbox-text {
	font-size: 0.9rem;
	margin-bottom: 0;
}

audio {
	max-width: 100% !important;
}

.selected_gr {
	display: grid;
	place-items: center;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
}
.selected-gr-item {
	width: 100%;
}
.audio_btn {
	cursor: pointer;
}

.audio_btn:hover {
	color: var(--mainColor);
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	from {
		opacity: 1;
		transform: scale(0.9, 0.9);
	}
	to {
		opacity: 0;
		transform: scale(1.5, 1.5);
	}
}

@media (max-width: 1200px) {
	.rec_session_container {
		grid-template-columns: 1fr 1fr 1fr !important;
		gap: 10px;
	}
}
@media (max-width: 1000px) {
	.rec_session_container {
		grid-template-columns: 1fr 1fr !important;
		gap: 10px;
	}
}

@media (max-width: 768px) {
	.text_card {
		padding: 30px;
		min-height: 150px;
	}

	.text_card > p {
		font-size: 0.9rem;
	}

	.rec_session_container {
		grid-template-columns: 1fr 1fr !important;
		gap: 10px;
	}

	.selected_gr {
		place-items: end;
	}
	.new_text_btn {
		height: 40px;
		width: 40px;
	}

	.record_btn {
		padding: 26px;
	}

	.recording_btn_active {
		padding: 26px;
	}

	.recording_btn_active::before {
		width: 80px;
		height: 80px;
	}

	small {
		font-size: 0.7rem !important;
	}
}

@media (max-width: 576px) {
	.rec_session_container {
		grid-template-columns: 1fr !important;
		gap: 10px;
	}
}
