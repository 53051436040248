@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
	font-family: 'Poppins', Helvetica, Arial, sans-serif;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

:root {
	--mainColor: #2c9599;
	--mainColorHover: #267f82;
	--buttonBorderColor: #137578;
	--mainColorLight: #65c6ca;
}

.main-container {
}

body {
	background-color: rgb(241, 241, 241) !important;
}

button {
	-webkit-appearance: button;
	cursor: pointer;
	font: inherit;
	overflow: visible;
	text-transform: none;
	padding: 0;
}

select {
	border: 0px solid gray !important;
	border-bottom: 2px solid rgb(201, 201, 201) !important;
	border-radius: 0px !important;
	padding-bottom: 10px !important;
	padding-top: 10px !important;
}

/* Buttons */

.btn_layout {
	padding: 18px 40px;
	font-size: 0.9rem;
	border-radius: 0px;
	letter-spacing: 0.6px;
	font-weight: 600;
	border: none;
}

@media (max-width: 568px) {
	.btn_layout {
		width: 100%;
	}
}
.btn_layout_small {
	padding: 10px 40px;
	font-size: 0.8rem;
	border-radius: 15px;
	letter-spacing: 0.1px;
	font-weight: 700;
	border: none;
	border-bottom: 5px solid var(--buttonBorderColor);
}
.btn_layout_xs {
	padding: 5px 25px;
	font-size: 0.8rem;
	letter-spacing: 0.1px;
	font-weight: 700;
	border: none;
}
.btn_layout_mail {
	font-size: 0.8rem;
	border-radius: 0px 0px 0px 0px;
	height: 100%;
	right: 0px;
	letter-spacing: 0.3px;
	font-weight: 600;
	border: none;
	padding: 0px 20px;
	bottom: 0px;
	position: absolute;
}

.del_btn_layout {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	border: none;
	background: none;
}

.del_btn_layout:hover {
	color: rgb(88, 88, 88);
}

.btn_blue {
	background: var(--mainColor);
	color: white;
	transition: 200ms ease-in-out;
}

.btn_blue:hover {
	background: var(--mainColorHover);
}
.btn_uploading {
	background: #24be83;
	color: white;
	transition: 200ms ease-in-out;
}

.btn_uploading:hover {
	background: #1da06e;
}
.btn_secondary {
	color: var(--mainColor);
	border: 2px solid var(--mainColor);
	background: none;
	transition: 200ms ease-in-out;
}

.btn_secondary:hover {
	background: var(--mainColor);
	color: white;
}
.btn_black {
	background: #333333;
	color: white;
}

.btn_black:hover {
	background: #070707;
}

.btn_new {
	border: 3px solid var(--mainColor);
	background: none;
	color: var(--mainColor);
}

.btn_new:hover {
	background-color: var(--mainColor);
	color: white;
}

.input-field {
	background-color: white;
	padding: 15px 20px !important;
	font-weight: 500 !important;
	border: 0px solid rgb(205, 205, 205);
	outline: none;
	border-radius: 0px;
	font-size: 0.9rem;
	width: 100%;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.input-field::placeholder {
	font-size: 0.9rem;
	font-weight: 400 !important;
}

.link-btn {
	padding-left: 0px;
	padding-top: 2px;
	font-weight: 600;
	color: var(--mainColorHover);
	font-size: 0.9rem !important;
	cursor: pointer;
	border: none;
	background: none;
	text-decoration: none;
}

.link-btn:hover {
	text-decoration-color: var(--mainColorHover);
	text-decoration: 1px underline !important;
	text-underline-offset: 4px;
}

a {
	font-weight: 600;
	font-size: 1rem !important;
	text-decoration-thickness: 1px !important;
	text-underline-offset: 3px !important;
	cursor: pointer;
	border: none;
}

a:hover {
	text-decoration-color: var(--mainColorHover);
	color: var(--mainColorHover) !important;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 3px;
}

a:visited {
	color: var(--mainColor);
}

form .form-select:focus {
	box-shadow: inset 0px 0px 0px 0px var(--mainColor);
}

.dev_text {
	font-weight: 600;
	font-size: 0.8rem;
	color: white;
	display: inline-block;
	padding: 3px 10px;
	letter-spacing: 0.8px;
	border-radius: 0px;
	background: rgba(217, 18, 0, 0.557);
}

select {
	font-size: 0.9rem !important;
	border: none;
	border-radius: 0px;
	padding: 10px;
	border: 1px solid rgb(224, 224, 224);
}

label {
	font-size: 0.9rem !important;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 10px;
	display: block !important;
	text-align: center;
}

button:disabled,
button[disabled] {
	cursor: wait;
	opacity: 0.9;
}
.btn_layout_mail:disabled,
.btn_layout_mail[disabled] {
	cursor: wait;
	opacity: 0.8;
}

@media (max-width: 576px) {
	.input-field {
		width: 100%;
		font-size: 0.8rem;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fade-in-top {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fade-in-top-lg {
	0% {
		opacity: 0;
		transform: translateY(-150px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
