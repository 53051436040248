* {
	margin: 0;
}
.home_container {
	margin-top: 180px;
	max-width: 800px !important;
	
}

.headline_wrapper > small {
	font-weight: 600;
}

.headline_wrapper {
	text-align: center;
	place-items: center !important;
	font-weight: 500;

}

.modal-content {
	border: 0px solid gray !important;
	border-radius: 0px !important;
	padding: 20px;
	color: black;
	font-size: 0.9rem;
	line-height: 1.5;
	width: 600px !important;
}

.headline_title {
	font-size: 5rem;
	font-weight: 900;
	letter-spacing: -1px;
	background: linear-gradient(to right, #0b688a, #27f0cf);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 0px;
	animation: fade-in-top 400ms forwards
}

.logo_gr {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
	margin-bottom: 80px;
}

.logo-gr_item_1 {
	justify-self: end !important;
}

.logo-gr_item_2 {
	justify-self: start !important;
}
.headline_text {
	position: relative;
	display: inline-block;
	padding: 10px 30px;
	border-radius: 15px 15px 15px 15px;
	margin-bottom: 25px;
}
.headline_text > p {
	font-size: 1rem;
	color: #1f1f1f;
	font-weight: 600;
	margin-bottom: 0px;
}

.bubble_arrow {
	background: white;
	height: 20px;
	width: 20px;
	position: absolute;
	bottom: -10px;
	left: 50%;
	z-index: 0 !important;

	transform: translateX(-50%) rotate(45deg);
}
.headline_sub_text {
	font-size: 1rem;
	color: #2c2c2c;
	margin-top: 0px !important;
	font-weight: 700;
	margin-bottom: 80px;
	line-height: 1.6;
}

@media (max-width: 700px) {
	.modal-content {
		width: 100% !important;
		padding: 20px 20px;
	}
}

@keyframes slide-in-left {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0%);
	}
}

@keyframes slide-in-bottom {
	from {
		transform: translateY(200%);
	}
	to {
		transform: translateY(0%);
	}
}

@media (max-width: 768px) {
	.headline_title {
		font-size: 3.5rem;
	}

	.headline_text {
		font-size: 0.9rem;
	}

	.info_header {
		font-size: 1.4rem !important;
	}

	.headline_sub_text {
		font-size: 0.9rem;
	}
}

.info_container {
	margin-top: 100px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	animation: fade ease-in-out 3000ms;
}

.headline_info_text {
	max-width: 570px;
	text-align: left;
	word-spacing: 4px;
	font-weight: 300;
	font-size: 1.7rem;
	margin-right: 30px;
}

.info_text {
	line-height: 1.6;
	font-weight: 400;
	font-size: 0.9rem !important;
}

.info_header {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 25px;
}

.info_link {
	text-decoration: none;
}

.info_link:hover {
	text-decoration: none;
}

.created_by {
	animation: slide-in-bottom ease-in-out 1000ms;
}
