.navbar {
	height: 100px;
	position: fixed;
	width: 100%;
	top: 0px;
	margin-bottom: 3px;
}

.nav-gr_container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.recording_count {
	animation: fade-in-top 400ms forwards;
}
.nav-gr_item {
	color: #333333;
	font-weight: 500;
	font-size: 1.2rem;
	align-self: center;
	gap: 20px;
}

.back_btn {
	background: white;
	border: 0px solid rgb(227, 227, 227);
	border-radius: 50%;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	height: 50px;
	width: 50px;
}
.back_btn:hover {
	background: rgb(242, 242, 242);
}
