.terms_header {
	font-weight: 700;
	font-size: 1.8rem;
	font-family: 'Poppins';
}

.terms_text {
	font-size: 0.9rem;
	line-height: 1.7;
}

.terms_container {
	margin-top: 80px;

}
.terms_container > h2 {
	font-size: 1.2rem;
	font-weight: 600;
	font-family: sofia-pro, 'Poppins';
}

@media (max-width: 568px) {
	.terms_container {
		padding: 0px 30px !important;
	}
}
