.thanks_header {
	font-size: 3rem;
	font-weight: 900;
	background: linear-gradient(to right, #0b688a, #1fc5a8);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-bottom: 0px;
	letter-spacing: -0.5px;
}

.finished_container {
	max-width: 800px !important;
	/* background: white; */
	border-radius: 5px;
	padding: 10px 10px;
	/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
	animation: fade ease-in-out 1000ms;
	margin-top: 100px;
}
.to_mail_headline {
	font-size: 0.9rem !important;
	font-weight: 600;
	line-height: 1.6 !important;
}

.ref_to_mail_form {
	position: relative;
}
.ref_to_mail_form > p {
	font-size: 0.9rem;
	text-align: center;
	font-weight: 400;
}
form > small {
	color: rgb(93, 93, 93);
	margin-top: 5px;
	font-size: 0.8rem;
	font-weight: 500;
	margin-bottom: 10px;
}
.finished_container > small {
	color: rgb(101, 101, 101);
	margin-top: 15px !important;
	font-size: 0.8rem;
	font-weight: 500;
}
.finished_container > p {
	font-size: 0.9rem;
	margin-top: 20px;
	line-height: 1.8;
	letter-spacing: 0.3px;
	text-align: center !important;
}

.ref_to_mail {
	background: none;

	padding: 0px 150px;
	border-radius: 10px;
	margin-top: 50px;
	animation: fade ease-in-out 1000ms;
	margin-bottom: 80px;
}
.ref_nr {
	font-size: 1rem;
	margin-top: 10px;
	letter-spacing: 0.3px;
}

.submit_mail {
	text-align: center;
}

@media (max-width: 768px) {
	.finished_container {
		padding: 30px !important;
	}

	.to_mail_headline {
		font-size: 0.9rem !important;
	}

	.thanks_header {
		font-size: 1.7rem;
	}

	.finished_container > p {
		font-size: 0.9rem;
	}

	.ref_to_mail {
		padding: 0px;
	}
}
